export default class Popover {
	constructor(element) {
		this.duration = 425; // Transition time
		this.element = element;
		this.init();
	}
	
	init() {
		this.bindUIActions();
	}

	bindUIActions() {
		$(this.element).on('click', this.toggleState.bind(this));
		$(this.element).on('mouseleave', e => $(e.target).blur());

		$(window).on('resize', this.onResize.bind(this));

		$(document).on('click', this.closeAllPopovers.bind(this));
	}

	toggleState(e) {
		const $target = $(e.target);

		this.closePopovers(e);

		$target.toggleClass('popover-btn--is-active');
		$target.parent().toggleClass('popover--is-hidden');

		$target.parent().promise().done(() => {
			setTimeout(() => {
				this.reposition();
			}, this.duration);
		});
	}

	closePopovers(e) {
		const id = $(`#${e.target.id}`).attr('aria-describedby');
		const items = $(`.popover:not(#${id})`);

		if (items.length === 0) {
			return;
		}

		items.each((i) => {
			$(items[i]).addClass('popover--is-hidden');
			$(`.popover-btn:not(#${e.target.id})`).removeClass('popover-btn--is-active');
		});
	}

	closeAllPopovers(e) {
		if (e && typeof e !== 'undefined') {
			let elements = $('.popover, .popover__container, .popover__content, .popover__heading, .popover__desc, .popover-btn');
			let isElement = elements.filter((i, elem) => $(elem).is(e.target)).length;
	
			if (isElement > 0) {
				return;
			}
		}

		$('.popover-btn').each(function () {
			if ($(this).hasClass('popover-btn--is-active')) {
				$(this).removeClass('popover-btn--is-active');

				$(this).parent().removeClass('popover--reverse').addClass('popover--is-hidden');
			}
		})
	}

	reposition() {
		this.onResize();
	}

	onResize() {
		let button = this.element;
		let popover = button.parentNode;
		let container = popover.querySelector('.popover__container');
		let container_rect = container.getBoundingClientRect();

		if (container_rect.left < 0 || (container_rect.left + container_rect.width) < 0) {
			popover.classList.add('popover--reverse');
		} else if ((container_rect.left + container_rect.width) > (window.pageXOffset + container_rect.width)) {
			popover.classList.remove('popover--reverse');
		}
	}

};