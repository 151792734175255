import { closeButton } from '../lib/utils';

export const modal = {

	elem: {
		$playBtn: $('.play-btn').length > 0 ? $('.play-btn') : null
	},

	options: {
		type: 'video',
		hide_close: true,
		animation_speed: 425,
		after_open() {
			const closeModaalButton = closeButton('close-btn', 'Close modal', 'play-btn');

			$('.modaal-content, .modaal-video-container').prepend(closeModaalButton);

			let timer = setInterval(() => {
				let $closeButton = $('.close-btn[data-selector="play-btn"]');

				if ($closeButton && $closeButton.is(':focus') === false) {
					$closeButton.focus();
				}

				clearInterval(timer);
			}, 1);
		}
	},

	init() {
		if (this.elem.$playBtn !== null) {
			this.bindUIActions();
		}
	},

	bindUIActions() {
		$(document).on('click', '.close-btn', function (e) {
			e.preventDefault();

			const selector = $(this).data('selector');
			$('.' + selector).modaal('close');
		});

		this.elem.$playBtn.modaal(this.options);
	}

};