export default class Accordion {
	constructor(element, options) {
		this.defaults = { // Defaults
			duration: 425,
			easing: 'swing',
			callback() {}
		};

		this.element = element;
		this.options = $.extend({}, this.defaults, options);

		this.init();
	}

	init() {
		this.bindUIActions();
		this.autoExpand();

		// Callback
		if (typeof this.options.callback !== 'undefined' && typeof this.options.callback === 'function') {
			this.options.callback.call(this, this.element);
		}
	}

	autoExpand() {
		const _this = this; // Context
		const $accordions = $(this.element).find('.accordion__panel');

		if ($accordions.length > 0) {
			$accordions.each(function () {
				if ($(this).hasClass('accordion__panel--is-active')) {
					$(this).find('.accordion__title').children('.accordion__trigger').first().attr({
						'aria-expanded': 'true',
						'aria-pressed': 'true'
					});

					$(this)
						.children('.accordion__content')
						.first()
						.attr('aria-hidden', 'false')
						.slideToggle(_this.options.duration, _this.options.easing);
				}
			});
		}
	}

	bindUIActions() {
		$(this.element).on('click', '.accordion__trigger', this.onClick.bind(this));

		$(this.element).on('keydown', '.accordion__trigger', this.onKeyDown.bind(this));

		$(this.element).on('mouseleave', '.accordion__trigger', (e) => {
			$(e.target).blur()
		});
	}

	onClick(e) {
		e.preventDefault();

		const $content = $(e.target).closest('.accordion__panel').find('.accordion__content').first();
		const $panel = $(e.target).closest('.accordion__panel');

		// Current target
		$(e.target).attr('aria-expanded', (i, currentValue) => (currentValue === 'false') ? 'true' : 'false');
		$(e.target).attr('aria-pressed', (i, currentValue) => (currentValue === 'false') ? 'true' : 'false');

		// Panel
		$panel.toggleClass('accordion__panel--is-active');

		// Content
		$content.attr('aria-hidden', (i, currentValue) => (currentValue === 'true') ? 'false' : 'true');

		$content.slideToggle(this.options.duration, this.options.easing);
	}

	onKeyDown(e) {
		const code = (e.keyCode) ? e.keyCode : e.which;

		// Down arrow
		if (code === 38) {
			$(e.target).closest('.accordion__panel').prev().find('.accordion__trigger').focus();
		}

		// Up arrow
		if (code === 40) {
			$(e.target).closest('.accordion__panel').next().find('.accordion__trigger').focus();
		}
	}
}