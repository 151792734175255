import { playButton, toggleVideoPlay } from '../lib/utils';

const masthead = {

	elem: {
		$masthead: null
	},

	init() {
		this.elem.$masthead = ($('.masthead').length > 0) ? $('.masthead') : null;

		if (this.elem.$masthead === null) {
			return;
		}

		this.bindUIActions();
		this.onLoad();
	},

	bindUIActions() {
		this.elem.$masthead.on('click', '.btn--play-btn', toggleVideoPlay);
		this.elem.$masthead.on('mouseleave', '.btn--play-btn', e => $(e.target).blur());
	},

	onLoad() {
		const hasVideo = this.elem.$masthead.data('has-video');

		if (!hasVideo) {
			return;
		}

		this.elem.$masthead.append(playButton());
	}

};

export { masthead };