// Lib
import { ready } from './lib/utils';
import { plugin } from './lib/plugin';
// Modules
import { masthead } from './modules/masthead';
import { modal } from './modules/modal';
// Plugins
import Accordion from './modules/accordion';
import Popover from './modules/popover';

const home = {

	elem: {
		$accordion: $('.accordion'),
		$popoverButton: $('.popover-btn')
	},

	init() {
		// Init plugins
		plugin('Accordion', Accordion);
		plugin('Popover', Popover);

		// Modules
		masthead.init();
		modal.init();

		this.bindUIActions();
	},

	bindUIActions() {
		// Plugins
		this.elem.$accordion.Accordion();
		this.elem.$popoverButton.Popover();
	}

};

ready(function () {
	home.init();
});